(function($) {
  /* globals site */
  window.site = site || {};
  site.loyalty = site.loyalty || {};
  var loyaltySettings = typeof Drupal.settings.loyalty === 'object' ? Drupal.settings.loyalty : {};
  site.loyalty = {
    enrollmentBtn: function(context) {
      var $joinBtn = $('.js-join-popup', context);
      var signedIn = site.userInfoCookie.getValue('signed_in') - 0;

      $joinBtn.on('click', function(event) {
        event.preventDefault();
        // just submit the form
        if (signedIn) {
          if (loyaltySettings.registration_endpoint_online) {
            window.location = window.location.protocol + '//' + window.location.hostname + '/' + loyaltySettings.registration_endpoint_online;
            return;
          }
        } else {
          if (loyaltySettings.registration_endpoint) {
            window.location = window.location.protocol + '//' + window.location.hostname + '/' + loyaltySettings.registration_endpoint;
            return;
          }
        }
      });
    },
  };
})(jQuery);
